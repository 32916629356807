import * as React from "react"
import { graphql, navigate } from 'gatsby'
import App from '../components/App'
import '../styles/global.css'

import Nav from '../components/Nav'
import HeroSection from '../components/HeroSection'
import IntroSection from '../components/IntroSection'
import PhotoSection from '../components/PhotoSection'
import ContactSection from '../components/ContactSection'
import Footer from '../components/Footer'
import Tracking from '../components/Tracking'

export const query = graphql`
query EventsQuery($pathname: String) {
  allPagesYaml(filter: {path: {eq: $pathname}}) {
    edges {
      node {
        sections {
          id
          type
          heading
          content
          subject
          ...PhotoSectionFragment
          ...HeroSectionFragment
        }
      }
    }
  }
}
`


const components = {
  // Nav,
  // HeroSection,
  // IntroSection,
  // PhotoSection,
  // ContactSection,
  // Footer,
  // Tracking,
}

export default function EventsPage(props) {
  const sections = props.data && props.data.allPagesYaml.edges[0].node.sections
  if (typeof window !== 'undefined') navigate('/')
  return null
  // return (
  //   <App
  //     sections={sections}
  //     components={components}
  //   />    
  // )
}
